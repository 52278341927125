import PropTypes from 'prop-types';
import Select from 'react-select';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import {
  Modal, Form,
} from 'react-bootstrap';
import api from '../../../config/configApi';
import { formatToBRL, formatBRLToFloat } from '../../../utils/generic';

export default function ModalRecebimento({
  itemPagamento = {},
  metodosDePagamento = {},
  advogados = {},
  atendentes = {},
  editarButtonRef = {},
  setPagamento = {},
  itemIndex = undefined,
}) {
  const [showModal, setShowModal] = useState(false);
  const options = atendentes?.atendentes?.map((item) => ({
    value: item.id,
    label: item.nome,
  }));
  const [loading, setLoading] = useState(false); // Added loading state

  const updateItemPagamento = (field, newValue) => {
    setPagamento((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[itemIndex][field] = newValue;
      return updatedItems;
    });
  };

  const atualizarItemPagamento = async () => {
    setLoading(true); // Set loading to true when the request starts
    try {
      const { pessoaId } = itemPagamento;
      const pagamentoId = itemPagamento.id;

      if (!pessoaId || !pagamentoId) {
        alert('Erro: Identificação da pessoa ou pagamento não encontrada.');
        return;
      }

      console.log({ ...itemPagamento });

      const response = await api.patch(`pessoa/${pessoaId}/pagamento/${pagamentoId}`, { ...itemPagamento });

      if (response.status === 200) {
        alert('Pagamento atualizado com sucesso!');
        setShowModal(false);
      } else {
        alert('Erro ao atualizar pagamento.');
      }
    } catch (error) {
      console.error('Erro ao atualizar pagamento:', error);
      alert('Erro ao salvar alterações.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="d-flex">
        <Button ref={editarButtonRef} variant="primary m-1" onClick={() => setShowModal(true)}>
          <FontAwesomeIcon icon={faPen} />
          Editar
        </Button>
      </div>
      <Modal show={showModal} size="xl" onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Dados</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-xl-2 col-md-6 mb-1">
              <label>Valor Total*</label>
              <div className="input-group">
                <span className="input-group-text">R$</span>
                <input
                  type="text"
                  name="amount"
                  placeholder="Digite o valor"
                  className="form-control"
                  value={
                    formatToBRL(
                      itemPagamento.amount
                        ? parseFloat(itemPagamento.amount).toFixed(2)
                        : 0,
                    )
                  }
                  onChange={(e) => {
                    updateItemPagamento('amount', formatBRLToFloat(formatToBRL(e.target.value)));
                  }}
                />
              </div>
            </div>

            <div className="col-xl-3 col-md-6 mb-1">
              <Form.Group controlId="validationFormikPaymentCategory">
                <Form.Label>Categoria do Pagamento*</Form.Label>
                <Form.Select
                  aria-label="Selecione uma categoria de pagamento"
                  name="paymentCategory"
                  value={itemPagamento.paymentCategory}
                  onChange={((e) => {
                    updateItemPagamento('paymentCategory', e.target.value);
                  })}
                >
                  <option value="Selecione">Selecione</option>
                  <option value="Entrada">Entrada</option>
                  <option value="Desconto">Desconto</option>
                  <option value="Parcela 1">Parcela 1</option>
                  <option value="Parcela 2">Parcela 2</option>
                  <option value="Parcela 3">Parcela 3</option>
                  <option value="Parcela 4">Parcela 4</option>
                  <option value="Parcela 5">Parcela 5</option>
                  <option value="Parcela 6">Parcela 6</option>
                  <option value="Parcela 7">Parcela 7</option>
                  <option value="Parcela 8">Parcela 8</option>
                  <option value="Parcela 9">Parcela 9</option>
                  <option value="Parcela 10">Parcela 10</option>
                  <option value="Parcela 11">Parcela 11</option>
                  <option value="Parcela 12">Parcela 12</option>
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-xl-2 col-md-6 mb-1">
              <Form.Group controlId="validationFormikMetodoDePagamento">
                <Form.Label>Tipo de Pagamento*</Form.Label>
                <Form.Select
                  aria-label="Selecione o tipo de pagamento"
                  name="metodoPagamentoId"
                  value={itemPagamento.metodoPagamentoId}
                  onChange={((e) => {
                    setPagamento((prevItems) => {
                      const updatedItems = [...prevItems];
                      updatedItems[itemIndex] = {
                        ...updatedItems[itemIndex],
                        metodoPagamentoId: e.target.value,
                        metodopagamento: {
                          nome: e.target.options[e.target.selectedIndex].text,
                          id: e.target.value,
                        },
                      };
                      return updatedItems;
                    });
                  })}
                >
                  <option value="selecione">Selecione</option>
                  {metodosDePagamento?.map((metodoPagamento) => (
                    <option key={metodoPagamento.id} value={metodoPagamento.id}>
                      {metodoPagamento.nome}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-xl-2 col-md-6 mb-3">
              <label>Data de Vencimento*</label>
              <input
                type="date"
                name="dueDate"
                className="form-control"
                value={itemPagamento.dueDate}
                onChange={((e) => {
                  updateItemPagamento('dueDate', e.target.value);
                })}
              />
            </div>
            <div className="col-xl-2 col-md-6 mb-3">
              <Form.Group controlId="validationFormikStatus">
                <Form.Label>Status do Pagamento*</Form.Label>
                <Form.Select
                  aria-label="Selecione o status do pagamento"
                  name="status"
                  value={itemPagamento.status}
                  onChange={((e) => {
                    updateItemPagamento('status', e.target.value);
                  })}
                >
                  <option value="selecione">Selecione</option>
                  <option value="em-aberto">Em Aberto</option>
                  <option value="pago">Pago</option>
                  <option value="em-atraso">Em Atraso</option>
                </Form.Select>
              </Form.Group>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-2 col-md-6 mb-3">
              <label>Nº de Controle*</label>
              <input
                type="text"
                name="trackingNumber"
                placeholder="Digite o nº de controle"
                className="form-control"
                value={itemPagamento.trackingNumber}
                onChange={((e) => {
                  updateItemPagamento('trackingNumber', e.target.value);
                })}
              />
            </div>

            <div className="col-xl-2 col-md-6 mb-3">
              <label>Data de Pagamento*</label>
              <input
                type="date"
                name="paidAt"
                className="form-control"
                value={itemPagamento.paidAt}
                onChange={((e) => {
                  updateItemPagamento('paidAt', e.target.value);
                })}
                required
              />
            </div>

            <div className="col-xl-3 col-md-6 mb-3">
              <label>Observações</label>
              <textarea
                name="obs"
                className="form-control"
                rows="1"
                value={itemPagamento.obs}
                onChange={((e) => {
                  updateItemPagamento('obs', e.target.value);
                })}
              />
            </div>

            <div className="col-xl-2 col-md-6 mb-3">
              <Form.Group controlId="validationFormikAdvogado">
                <Form.Label>Advogado*</Form.Label>
                <Form.Select
                  aria-label="Selecione um advogado"
                  name="advogadoId"
                  value={itemPagamento.advogadoId}
                  onChange={((e) => {
                    setPagamento((prevItems) => {
                      const updatedItems = [...prevItems];
                      updatedItems[itemIndex] = {
                        ...updatedItems[itemIndex],
                        advogadoId: e.target.value || null,
                        advogado: {
                          nome: e.target.options[e.target.selectedIndex].text,
                          id: e.target.value,
                        },
                      };
                      return updatedItems;
                    });
                  })}
                >
                  <option value="">Selecione uma opção</option>
                  {advogados?.map((advogado) => (
                    <option key={advogado.id} value={advogado.id}>
                      {advogado.nome}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-xl-2 col-md-6 mb-3">
              <label>Códigos de Barras*</label>
              <input
                type="text"
                name="barCode"
                placeholder="Digite o nº do código de barras"
                className="form-control"
                value={itemPagamento.barCode}
                onChange={((e) => {
                  updateItemPagamento('barCode', e.target.value);
                })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-6 mb-4">
              <label htmlFor="reviewedById">Conferido Por</label>
              <Select
                id="reviewedById"
                name="reviewedById"
                className="my-1 mr-sm-2"
                value={
                  options?.find((option) => option.value === itemPagamento.reviewedById)
                  || null
                }
                onChange={((selectedOption) => {
                  console.log(selectedOption);
                  setPagamento((prevItems) => {
                    const updatedItems = [...prevItems];
                    updatedItems[itemIndex] = {
                      ...updatedItems[itemIndex],
                      reviewedById: selectedOption?.value || null,
                      reviewer: {
                        nome: selectedOption?.label,
                        id: selectedOption?.value || null,
                      },
                    };
                    return updatedItems;
                  });
                })}
                options={options?.sort((a, b) => a.label.localeCompare(b.label))}
                isSearchable
                placeholder="Selecione um Conferente"
                isClearable
              />
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <label htmlFor="recordedById"> Registrado Por</label>
              <Select
                className="my-1 mr-sm-2"
                id="recordedById"
                name="recordedById"
                value={
                  options?.find((option) => option.value === itemPagamento.recordedById)
                  || null
                }
                onChange={((selectedOption) => {
                  setPagamento((prevItems) => {
                    const updatedItems = [...prevItems];
                    updatedItems[itemIndex] = {
                      ...updatedItems[itemIndex],
                      recordedById: selectedOption?.value || null,
                      recorder: {
                        nome: selectedOption?.label,
                        id: selectedOption?.value || null,
                      },
                    };
                    return updatedItems;
                  });
                })}
                options={options?.sort((a, b) => a.label.localeCompare(b.label))}
                isSearchable
                placeholder="Selecione quem registrou"
                isClearable
              />
            </div>
            <div className="col-xl-2 col-md-6 mb-3">
              <label>Link para Arquivos*</label>
              <input
                type="text"
                name="filesUrl"
                placeholder="recibos, notas, docs."
                className="form-control"
                value={itemPagamento.filesUrl}
                onChange={((e) => {
                  updateItemPagamento('filesUrl', e.target.value);
                })}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Fechar
          </Button>
          <Button variant="primary" onClick={atualizarItemPagamento} disabled={loading}>
            {loading ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                />
                Salvando...
              </>
            ) : (
              'Salvar'
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

ModalRecebimento.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  itemPagamento: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  metodosDePagamento: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  advogados: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  atendentes: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  editarButtonRef: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  setPagamento: PropTypes.object.isRequired,
  itemIndex: PropTypes.number.isRequired,
};
