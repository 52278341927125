export default {
  ARQUIVO: 'Arquivo',
  ATENDIMENTO: 'Atendimento',
  CALLCENTER: 'Callcenter',
  DEFERIDO: 'Deferido',
  ENTRADA: 'Entrada',
  EXIGENCIA: 'Exigência',
  INDEFERIDO: 'Indeferido',
  INFORMACAO_DE_PROCESSO: 'Informação de Processo',
  PERICIA: 'Perícia',
  RECEBIMENTO: 'Recebimento',
  WHATSAAP_0800: 'Whatsaap 0800',
  COMPRAS_E_ORCAMENTOS: 'Compras e Orçamentos',
  RH_E_DP: 'RH e DP',
  CONTAS_A_PAGAR: 'Contas a pagar',
  CONTAS_A_RECEBER: 'Contas a receber',
  COBRANCA: 'Cobrança',
  TI: 'TI',
  SAC: 'Sac',
};
