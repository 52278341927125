import React, { useState, useEffect } from 'react';
import { Formik } from 'formik';
import {
  Form, Button, Alert, Spinner,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { ptBR } from 'date-fns/locale';
import { ChangeAlert } from '../../Shared/ChangeAlert';
import schema from './schema';
import estados from '../../../utils/estados';
import specialCharacters from '../../../utils/generic';
import api from '../../../config/configApi';
import { cpfMask } from '../../../utils/documentos';
import getCep from '../../../utils/cep';
import personStatus from '../../../utils/enums/personStatus';
import paymentStatus from '../../../utils/enums/paymentsStatus';

export default function AtendimentoForm(
  {
    pessoa,
    updateAllowedFinalizarAtendimento,
    isAtendimentoEncerrado,
    enableFormularios,
    isDadosComplementares,
    getUser,
  },
) {
  const [formDirty, setFormDirty] = React.useState(false);
  const [dirtyCalledTimes, setDirtyCalledTimes] = React.useState(0);
  const [showChangeAlert, setShowChangeAlert] = useState(false);
  const [alertError, setAlertError] = useState({ isVisible: false });
  const [alertSuccess, setAlertSuccess] = useState({ isVisible: false });
  const [spinnerSalvar, setSpinnerSalvar] = useState(false);
  const [beneficios, setBeneficios] = useState([]);
  const [advogados, setAdvogados] = useState([]);
  const [cpfError, setCpfError] = useState('');
  const [emailAlert, setEmailAlert] = useState('');
  const [formKey, setFormKey] = useState(0);
  const [atendentes, setAtendentes] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [indicacoes, setIndicacoes] = useState([]);
  const [assinouContratoAt, setAssinouContratoAt] = useState();
  const perfilMaster = '99';

  const markFormDirty = () => {
    setFormDirty(true);
    setDirtyCalledTimes((prevState) => prevState + 1);
  };
  useEffect(() => {
    // Whenever pessoa changes, increment the form key to force rerender
    setFormKey((prevKey) => prevKey + 1);
  }, [pessoa]);

  const updatePessoa = async (e) => {
    if (cpfError) return;
    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    setSpinnerSalvar(true);
    try {
      e.cpf = e.cpf.replace(/\D/g, '');
      e.procurador.cpf = e.procurador?.cpf?.replace(/\D/g, '');

      await api.put('/pessoa/pessoa', { ...e, assinouContratoAt }, headers);
      enableFormularios();
      updateAllowedFinalizarAtendimento(true);
      setDirtyCalledTimes(0);
      setShowChangeAlert(false);
      setAlertSuccess({
        isVisible: true,
        message: 'Dados salvos com sucesso!',
      });
      window.setTimeout(() => {
        setAlertSuccess({ isVisible: false });
      }, 5000);
      getUser();
    } catch (err) {
      setAlertError({
        isVisible: true,
        message: 'Falha ao atualizar pessoa. Por favor tente novamente.',
      });
      window.setTimeout(() => {
        setAlertError({ isVisible: false });
      }, 5000);
    }
    setSpinnerSalvar(false);
  };

  const validarCPF = async (cpf) => {
    try {
      if (
        !cpf?.target?.value
        || cpf?.target?.value?.match(/\d+/g)?.join('') === pessoa.cpf
      ) {
        setCpfError('');
        return;
      }
      const resposta = await api.post(
        '/pessoa/cpf/check-availability',
        { cpf: cpf?.target?.value?.match(/\d+/g)?.join('') },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (resposta.data.result.available) {
        setCpfError('');
        return;
      }
      setCpfError(resposta?.data?.result?.message || 'Erro ao validar CPF');
    } catch (erro) {
      console.error('Erro ao verificar CPF:', erro);
      setCpfError('Erro ao validar CPF');
    }
  };

  const validarEmail = async (e) => {
    const email = e.target.value || '';
    try {
      if (!email || email === pessoa.email) {
        setEmailAlert('');
        return;
      }

      const resposta = await api.post(
        '/pessoa/email/check-availability',
        { email },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (resposta.data.result.available) {
        setEmailAlert('');
      } else {
        setEmailAlert('Aviso: Este email já foi utilizado anteriormente');
      }
    } catch (erro) {
      console.error('Erro ao verificar email:', erro);
      setEmailAlert('Erro ao validar email');
    }
  };

  const searchCep = async (code, setFieldValue) => {
    const cep = await getCep(code);
    if (cep.logradouro) setFieldValue('logradouro', cep.logradouro.toUpperCase());
    if (cep.localidade) setFieldValue('cidade', cep.localidade.toUpperCase());
    if (cep.uf) setFieldValue('estado', cep.uf);
    if (cep.bairro) setFieldValue('bairro', cep.bairro.toUpperCase());
  };

  const searchCepProcurador = async (code, setFieldValue) => {
    const cep = await getCep(code);
    if (cep.logradouro) setFieldValue('procurador.logradouro', cep.logradouro.toUpperCase());
    if (cep.localidade) setFieldValue('procurador.cidade', cep.localidade.toUpperCase());
    if (cep.uf) setFieldValue('procurador.estado', cep.uf);
    if (cep.bairro) setFieldValue('procurador.bairro', cep.bairro.toUpperCase());
  };

  const getIndicacoes = async () => {
    await api
      .get('/indicacao/indicacoes')
      .then((response) => {
        setIndicacoes(response.data);
      })
      .catch(() => {
        alert('Erro ao buscar lista de indicações');
      });
  };

  const getAtendentes = async () => {
    const response = await api.get('/user/atendentes');
    setAtendentes(response.data);
  };

  const getBancos = async () => {
    const response = await api.get('/bancos');
    const formattedResponse = response?.data.map((b) => ({
      nome: [b.codigo, b.nome].join(' - '),
      id: b.id,
    }));

    const sortedFormattedResponse = formattedResponse?.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    setBancos(sortedFormattedResponse);
  };

  const getBeneficios = async () => {
    const { data } = await api.get('/beneficio/beneficios');
    setBeneficios(data.beneficios);
  };

  const getAdvogados = async () => {
    const { data } = await api.get('/advogados');
    setAdvogados(data.advogados);
  };

  useEffect(() => {
    getAtendentes();
    getBancos();
    getIndicacoes();
    getBeneficios();
    getAdvogados();
  }, []);

  return (
    <>
      <Formik
        key={formKey}
        validationSchema={schema}
        onSubmit={updatePessoa}
        initialValues={{
          ...pessoa,
          cpf: pessoa.cpf ? cpfMask(pessoa.cpf) : '',
          procurador: {
            ...pessoa.procurador,
            cpf: pessoa.procurador?.cpf ? cpfMask(pessoa.procurador?.cpf) : '',
          },
          assinouContrato: pessoa.assinouContrato || 'N',
        }}
        validateOnMount
        enableReinitialize
      >
        {({
          handleBlur,
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
        }) => (
          <Form noValidate onChange={markFormDirty} onSubmit={handleSubmit}>
            <fieldset disabled={isAtendimentoEncerrado}>
              <div className="card shadow mb-4">
                <div className="card-header">
                  <p className="mt-4">
                    DADOS DO REQUERENTE/SEGURADO/PENSIONISTA
                  </p>
                  <div className="row">
                    {!isDadosComplementares && (
                      <Form.Group
                        className="col-xl-2 col-md-6 mb-4"
                        controlId="validationFormikNome"
                      >
                        <Form.Label>Nome*</Form.Label>
                        <Form.Control
                          type="text"
                          name="nome"
                          value={values.nome || undefined}
                          onChange={handleChange}
                          isInvalid={!!errors.nome}
                          isValid={touched.nome && !errors.nome}
                          onBlur={handleBlur}
                          autoFocus
                        />
                        <Form.Control.Feedback />
                      </Form.Group>
                    )}
                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikSexo"
                    >
                      <Form.Label>Sexo</Form.Label>
                      <Form.Select
                        aria-label="Selecione uma opção"
                        name="sexo"
                        value={values.sexo || ''}
                        onChange={handleChange}
                        isValid={touched.sexo && !errors.sexo}
                        isInvalid={!!errors.sexo}
                        onBlur={handleBlur}
                      >
                        <option>Opção</option>
                        <option value="M">Masculino</option>
                        <option value="F">Feminino</option>
                      </Form.Select>
                    </Form.Group>

                    {!isDadosComplementares && (
                      <Form.Group
                        className="col-xl-2 col-md-6 mb-4"
                        controlId="validationFormikDataNascimento"
                      >
                        <Form.Label>Data de Nascimento</Form.Label>
                        <Form.Control
                          type="date"
                          name="datanascimento"
                          value={values.datanascimento || undefined}
                          onChange={handleChange}
                          isInvalid={!!errors.datanascimento}
                          isValid={
                            touched.datanascimento && !errors.datanascimento
                          }
                          onBlur={handleBlur}
                        />
                        <Form.Control.Feedback />
                      </Form.Group>
                    )}

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikEmail"
                    >
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        value={values.email || undefined}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                        isValid={touched.email && !errors.email && !emailAlert}
                        isInvalid={!!errors.email || !!emailAlert}
                        onBlur={(e) => {
                          handleBlur(e);
                          validarEmail(e);
                        }}
                        className={
                          !!errors.email || !!emailAlert ? 'border-success' : ''
                        }
                      />
                      {emailAlert && (
                        <Form.Control.Feedback
                          type="invalid"
                          className="text-warning"
                        >
                          {emailAlert}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikSenhaINSS"
                    >
                      <Form.Label>Senha INSS*</Form.Label>
                      <Form.Control
                        type="text"
                        name="senhaINSS"
                        value={values.senhaINSS || undefined}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.senhaINSS && !errors.senhaINSS}
                        isInvalid={!!errors.senhaINSS}
                      />
                      <Form.Control.Feedback />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikCPF"
                    >
                      <Form.Label>CPF</Form.Label>
                      <Form.Control
                        type="text"
                        name="cpf"
                        value={values.cpf || undefined}
                        onChange={(e) => {
                          validarCPF(e);
                          handleChange(e);
                        }}
                        isValid={touched.cpf && !errors.cpf}
                        isInvalid={!!errors.cpf || !!cpfError}
                      />
                      {cpfError && (
                        <Form.Control.Feedback type="invalid">
                          {cpfError}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </div>

                  <div className="row">
                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikRG"
                    >
                      <Form.Label>RG</Form.Label>
                      <Form.Control
                        type="text"
                        name="rg"
                        value={values.rg || undefined}
                        onChange={handleChange}
                        isValid={touched.rg && !errors.rg}
                        isInvalid={!!errors.rg}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikEtinia"
                    >
                      <Form.Label>Etnia</Form.Label>
                      <Form.Select
                        aria-label="Selecione uma opção"
                        name="etinia"
                        value={values.etinia || ''}
                        onChange={handleChange}
                        isValid={touched.etinia && !errors.etinia}
                        isInvalid={!!errors.etinia}
                        onBlur={handleBlur}
                      >
                        <option>Selecione Opção</option>
                        <option value="1">Branco</option>
                        <option value="2">Pardo</option>
                        <option value="3">Preto</option>
                        <option value="4">Amarelo</option>
                        <option value="5">Indígena</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikEstadoCivil"
                    >
                      <Form.Label>Estado Civil</Form.Label>
                      <Form.Select
                        aria-label="Selecione uma opção"
                        name="estadoCivil"
                        value={values.estadoCivil || ''}
                        onChange={handleChange}
                        isValid={touched.estadoCivil && !errors.estadoCivil}
                        isInvalid={!!errors.estadoCivil}
                        onBlur={handleBlur}
                      >
                        <option>Selecione Opção</option>
                        <option value="1">Solteiro</option>
                        <option value="2">Casado</option>
                        <option value="3">Separado</option>
                        <option value="6">Separado de Fato</option>
                        <option value="4">Divorciado</option>
                        <option value="5">Viúvo</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikEscolaridade"
                    >
                      <Form.Label>Escolaridade</Form.Label>
                      <Form.Select
                        aria-label="Selecione uma opção"
                        name="escolaridade"
                        value={values.escolaridade || ''}
                        onChange={handleChange}
                        isValid={touched.escolaridade && !errors.escolaridade}
                        isInvalid={!!errors.escolaridade}
                        onBlur={handleBlur}
                      >
                        <option>Selecione Opção</option>
                        <option value="1">Analfabeto</option>
                        <option value="2">Fundamental 1 Incompleto</option>
                        <option value="3">Fundamental 1 Completo</option>
                        <option value="4">Fundamental 2 Incompleto</option>
                        <option value="5">Fundamental 2 Completo</option>
                        <option value="6">Ensino Médio Incompleto</option>
                        <option value="7">Ensino Médio Completo</option>
                        <option value="8">Graduação Incompleto</option>
                        <option value="9">Graduação Completo</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikIsIdoso"
                    >
                      <Form.Label>Idoso</Form.Label>
                      <Form.Select
                        aria-label="Selecione uma opção"
                        name="isIdoso"
                        value={values.isIdoso || ''}
                        onChange={handleChange}
                        isValid={touched.isIdoso && !errors.isIdoso}
                        isInvalid={!!errors.isIdoso}
                        onBlur={handleBlur}
                      >
                        <option>Selecione Opção</option>
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikIsDeficiente"
                    >
                      <Form.Label>Deficiente físico</Form.Label>
                      <Form.Select
                        aria-label="Selecione uma opção"
                        name="isDeficiente"
                        value={values.isDeficiente || ''}
                        onChange={handleChange}
                        isValid={touched.isDeficiente && !errors.isDeficiente}
                        isInvalid={!!errors.isDeficiente}
                        onBlur={handleBlur}
                      >
                        <option>Selecione uma opção</option>
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                      </Form.Select>
                    </Form.Group>
                  </div>

                  <div className="form-row">
                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikIsEstrangeiro"
                    >
                      <Form.Label>Estrangeiro</Form.Label>
                      <Form.Select
                        aria-label="Selecione uma opção"
                        name="isEstrangeiro"
                        value={values.isEstrangeiro || ''}
                        onChange={handleChange}
                        isValid={touched.isEstrangeiro && !errors.isEstrangeiro}
                        isInvalid={!!errors.isEstrangeiro}
                        onBlur={handleBlur}
                      >
                        <option>Selecione uma opção</option>
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikCadUnico"
                    >
                      <Form.Label>CadÚnico</Form.Label>
                      <Form.Control
                        type="text"
                        name="cadUnico"
                        value={values.cadUnico || undefined}
                        onChange={handleChange}
                        isValid={touched.cadUnico && !errors.cadUnico}
                        isInvalid={!!errors.cadUnico}
                        onBlur={handleBlur}
                      />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikRequerimento"
                    >
                      <Form.Label>Número Requerimento</Form.Label>
                      <Form.Control
                        type="text"
                        name="numeroRequerimento"
                        value={values.numeroRequerimento || undefined}
                        onChange={handleChange}
                        isValid={touched.requerimento && !errors.requerimento}
                        isInvalid={!!errors.requerimento}
                        onBlur={handleBlur}
                      />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-6 col-md-6 mb-4"
                      controlId="validationFormikObservacao"
                    >
                      <Form.Label>Observação</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        type="text"
                        name="observacao"
                        value={values.observacao || undefined}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                        isValid={touched.observacao && !errors.observacao}
                        isInvalid={!!errors.observacao}
                        onBlur={handleBlur}
                      />
                    </Form.Group>
                    {isDadosComplementares && (
                      <Form.Group
                        className="col-xl-3 col-md-2 mb-4"
                        controlId="validationFormikIndicacaoPresencial"
                      >
                        <Form.Label>Indicação no Presencial</Form.Label>
                        <Form.Control
                          as="select"
                          className="custom-select my- -1 mr-sm-2"
                          name="indicacaoPresencial"
                          value={values.indicacaoPresencial || ''}
                          onChange={handleChange}
                          isValid={
                            touched.indicacaoPresencial
                            && !errors.indicacaoPresencial
                          }
                          isInvalid={!!errors.indicacaoPresencial}
                          onBlur={handleBlur}
                          disabled={
                            localStorage.getItem('perfilId') !== perfilMaster
                          }
                        >
                          <option value="">Selecione Indicação</option>
                          {indicacoes?.indicacoes?.map((item) => (
                            <option
                              key={item.nome}
                              value={item.nome}
                              selected={
                                item.nome === values.indicacaoPresencial
                              }
                            >
                              {item.nome}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    )}
                    {isDadosComplementares && (
                      <Form.Group
                        className="col-xl-4 col-md-6 mb-4"
                        controlId="validationFormikObservacaoIndicacaoPresencial"
                      >
                        <Form.Label>
                          Observação Indicação no Presencial
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="observacaoIndicacaoPresencial"
                          value={values.observacaoIndicacaoPresencial || ''}
                          onChange={(e) => {
                            e.target.value = e.target.value.toUpperCase();
                            handleChange(e);
                          }}
                          isValid={
                            touched.observacaoIndicacaoPresencial
                            && !errors.observacaoIndicacaoPresencial
                          }
                          isInvalid={!!errors.observacaoIndicacaoPresencial}
                          onBlur={handleBlur}
                          disabled={
                            localStorage.getItem('perfilId') !== perfilMaster
                          }
                        />
                      </Form.Group>
                    )}
                    {!isDadosComplementares && (
                      <Form.Group
                        className="col-xl-2 col-md-6 mb-4"
                        controlId="validationFormikBeneficio"
                      >
                        <Form.Label>Beneficio</Form.Label>
                        <Form.Select
                          aria-label="Selecione uma opção"
                          name="beneficio"
                          value={values.beneficio || ''}
                          onChange={handleChange}
                          isValid={touched.beneficio && !errors.beneficio}
                          isInvalid={!!errors.beneficio}
                          onBlur={handleBlur}
                        >
                          <option>Selecione uma opção</option>
                          {beneficios?.map((item) => (
                            <option key={item.nome} value={item.nome}>
                              {item.nome}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    )}

                    {!isDadosComplementares && (
                      <>
                        <Form.Group
                          className="col-xl-2 col-md-6 mb-4"
                          controlId="validationFormikSituacaoStatus"
                        >
                          <Form.Label>Situacao</Form.Label>
                          <Form.Select
                            aria-label="Selecione uma opção"
                            name="situacaoStatus"
                            value={values.situacaoStatus || ''}
                            onChange={handleChange}
                            isValid={
                              touched.situacaoStatus && !errors.situacaoStatus
                            }
                            isInvalid={!!errors.situacaoStatus}
                            onBlur={handleBlur}
                          >
                            <option value="">Selecione uma opção</option>
                            <optgroup label="Análise">
                              {Object.entries(personStatus.ANALISE).map(
                                ([code, name]) => (
                                  <option key={code} value={code}>
                                    {name}
                                  </option>
                                ),
                              )}
                            </optgroup>
                            <optgroup label="Decisão">
                              {Object.entries(personStatus.DECISAO).map(
                                ([code, name]) => (
                                  <option key={code} value={code}>
                                    {name}
                                  </option>
                                ),
                              )}
                            </optgroup>
                            <optgroup label="Interação">
                              {Object.entries(personStatus.INTERACAO).map(
                                ([code, name]) => (
                                  <option key={code} value={code}>
                                    {name}
                                  </option>
                                ),
                              )}
                            </optgroup>
                            <optgroup label="Processo">
                              {Object.entries(personStatus.PROCESSO).map(
                                ([code, name]) => (
                                  <option key={code} value={code}>
                                    {name}
                                  </option>
                                ),
                              )}
                            </optgroup>
                            <optgroup label="Outros">
                              {Object.entries(personStatus.OUTROS).map(
                                ([code, name]) => (
                                  <option key={code} value={code}>
                                    {name}
                                  </option>
                                ),
                              )}
                            </optgroup>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group
                          className="col-xl-2 col-md-6 mb-4"
                          controlId="validationFormikPagamentoStatus"
                        >
                          <Form.Label>Pagamento</Form.Label>
                          <Form.Select
                            aria-label="Selecione uma opção"
                            name="pagamentoStatus"
                            value={values.pagamentoStatus || ''}
                            onChange={handleChange}
                            isValid={
                              touched.pagamentoStatus && !errors.pagamentoStatus
                            }
                            isInvalid={!!errors.pagamentoStatus}
                            onBlur={handleBlur}
                          >
                            <option value="">Selecione uma opção</option>
                            {Object.entries(paymentStatus).map(
                              ([code, name]) => (
                                <option key={code} value={code}>
                                  {name}
                                </option>
                              ),
                            )}
                          </Form.Select>
                        </Form.Group>

                        <Form.Group className="col-xl-2 col-md-4 mb-4">
                          <Form.Label htmlFor="indicacaoPresencial">
                            Indicação no Presencial
                          </Form.Label>
                          <Form.Control
                            as="select"
                            className="custom-select my- -1 mr-sm-2"
                            name="indicacaoPresencial"
                            id="indicacaoPresencial"
                            placeholder="indicacaoPresencial"
                            value={values.indicacaoPresencial}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Selecione Indicação</option>
                            {indicacoes?.indicacoes?.map((item) => (
                              <option
                                key={item.nome}
                                value={item.nome}
                                selected={
                                  item.nome === values.indicacaoPresencial
                                }
                              >
                                {item.nome}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>

                        <Form.Group
                          className="col-xl-4 col-md-6 mb-4"
                          controlId="validationFormikobservacaoIndicacaoPresencial"
                        >
                          <Form.Label>
                            Observação Indicação no Presencial
                          </Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={1}
                            type="text"
                            name="observacaoIndicacaoPresencial"
                            value={
                              values.observacaoIndicacaoPresencial || undefined
                            }
                            onChange={(e) => {
                              e.target.value = e.target.value.toUpperCase();
                              handleChange(e);
                            }}
                            isValid={
                              touched.observacaoIndicacaoPresencial
                              && !errors.observacaoIndicacaoPresencial
                            }
                            isInvalid={!!errors.observacaoIndicacaoPresencial}
                            onBlur={handleBlur}
                          />
                        </Form.Group>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="card shadow mb-4">
                <div className="card-header">
                  <p className="mt-4">CONTATO DO CLIENTE</p>

                  <div className="row">
                    <Form.Group
                      className="col-xl-1 col-md-6 mb-4"
                      controlId="validationFormikCep"
                    >
                      <Form.Label>CEP</Form.Label>
                      <Form.Control
                        type="text"
                        name="cep"
                        value={values.cep || undefined}
                        onChange={async (e) => {
                          const cepCode = e.target?.value?.replace(
                            specialCharacters,
                            '',
                          );
                          if (cepCode.length === 8) await searchCep(cepCode, setFieldValue);
                          setFieldValue('cep', cepCode);
                        }}
                        isValid={touched.cep && !errors.cep}
                        isInvalid={!!errors.cep}
                        onBlur={handleBlur}
                      />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikLogradouro"
                    >
                      <Form.Label>Logradouro</Form.Label>
                      <Form.Control
                        type="text"
                        name="logradouro"
                        value={values.logradouro || undefined}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                        isValid={touched.logradouro && !errors.logradouro}
                        isInvalid={!!errors.logradouro}
                        onBlur={handleBlur}
                      />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-1 col-md-6 mb-4"
                      controlId="validationFormikNumero"
                    >
                      <Form.Label>Numero</Form.Label>
                      <Form.Control
                        type="text"
                        name="numero"
                        value={values.numero || undefined}
                        onChange={handleChange}
                        isValid={touched.numero && !errors.numero}
                        isInvalid={!!errors.numero}
                        onBlur={handleBlur}
                      />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikComplemento"
                    >
                      <Form.Label>Complemento</Form.Label>
                      <Form.Control
                        type="text"
                        name="complemento"
                        value={values.complemento || undefined}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                        isValid={touched.complemento && !errors.complemento}
                        isInvalid={!!errors.complemento}
                        onBlur={handleBlur}
                      />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikBairro"
                    >
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control
                        type="text"
                        name="bairro"
                        value={values.bairro || undefined}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                        isValid={touched.bairro && !errors.bairro}
                        isInvalid={!!errors.bairro}
                        onBlur={handleBlur}
                      />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikCidade"
                    >
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        type="text"
                        name="cidade"
                        value={values.cidade || undefined}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                        isValid={touched.cidade && !errors.cidade}
                        isInvalid={!!errors.cidade}
                        onBlur={handleBlur}
                      />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikEstado"
                    >
                      <Form.Label>Estado</Form.Label>
                      <Form.Select
                        aria-label="Selecione uma opção"
                        name="estado"
                        value={values.estado || ''}
                        onChange={handleChange}
                        isValid={touched.estado && !errors.estado}
                        isInvalid={!!errors.estado}
                        onBlur={handleBlur}
                      >
                        <option>Selecione uma opção</option>
                        {Object.entries(estados).map((estado) => (
                          <option key={estado[1]} value={estado[1]}>
                            {estado[0]}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                  {!isDadosComplementares && (
                    <div className="row">
                      <Form.Group
                        className="col-xl-2 col-md-6 mb-4"
                        controlId="validationFormikTel"
                      >
                        <Form.Label>Telefone</Form.Label>
                        <Form.Control
                          type="text"
                          name="telefoneprincipal"
                          value={values.telefoneprincipal || undefined}
                          onChange={handleChange}
                          isValid={
                            touched.telefoneprincipal
                            && !errors.telefoneprincipal
                          }
                          isInvalid={!!errors.telefoneprincipal}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                      <Form.Group
                        className="col-xl-2 col-md-6 mb-4"
                        controlId="validationFormikTelObs"
                      >
                        <Form.Label>Obs Telefone)</Form.Label>
                        <Form.Control
                          type="text"
                          as="textarea"
                          rows={1}
                          name="obstelefoneprincipal"
                          value={values.obstelefoneprincipal || undefined}
                          onChange={handleChange}
                          isValid={
                            touched.obstelefoneprincipal
                            && !errors.obstelefoneprincipal
                          }
                          isInvalid={!!errors.obstelefoneprincipal}
                          onBlur={handleBlur}
                        />
                      </Form.Group>

                      <Form.Group
                        className="col-xl-2 col-md-6 mb-4"
                        controlId="validationFormikTel2"
                      >
                        <Form.Label>2º Telefone</Form.Label>
                        <Form.Control
                          type="text"
                          name="telefonesegundo"
                          value={values.telefonesegundo || undefined}
                          onChange={handleChange}
                          isValid={
                            touched.telefonesegundo && !errors.telefonesegundo
                          }
                          isInvalid={!!errors.telefonesegundo}
                          onBlur={handleBlur}
                        />
                      </Form.Group>

                      <Form.Group
                        className="col-xl-2 col-md-6 mb-4"
                        controlId="validationFormikTel2Obs"
                      >
                        <Form.Label>Obs 2º Telefone)</Form.Label>
                        <Form.Control
                          type="text"
                          as="textarea"
                          rows={1}
                          name="obstelefonesegundo"
                          value={values.obstelefonesegundo || undefined}
                          onChange={handleChange}
                          isValid={
                            touched.obstelefonesegundo
                            && !errors.obstelefonesegundo
                          }
                          isInvalid={!!errors.obstelefonesegundo}
                          onBlur={handleBlur}
                        />
                      </Form.Group>

                      <Form.Group
                        className="col-xl-2 col-md-6 mb-4"
                        controlId="validationFormikTelOutros"
                      >
                        <Form.Label>3º Telefone</Form.Label>
                        <Form.Control
                          type="text"
                          name="telefoneoutros"
                          value={values.telefoneoutros || undefined}
                          onChange={handleChange}
                          isValid={
                            touched.telefoneoutros && !errors.telefoneoutros
                          }
                          isInvalid={!!errors.telefoneoutros}
                          onBlur={handleBlur}
                        />
                      </Form.Group>

                      <Form.Group
                        className="col-xl-2 col-md-6 mb-4"
                        controlId="validationFormikTelOutrosObs"
                      >
                        <Form.Label>Obs 3º Telefone)</Form.Label>
                        <Form.Control
                          type="text"
                          as="textarea"
                          rows={1}
                          name="obstelefoneoutros"
                          value={values.obstelefoneoutros || undefined}
                          onChange={handleChange}
                          isValid={
                            touched.obstelefoneoutros
                            && !errors.obstelefoneoutros
                          }
                          isInvalid={!!errors.obstelefoneoutros}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                    </div>
                  )}
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-header">
                  <p className="mt-4">PROCURADOR(A) / REPRESENTANTE LEGAL</p>
                  <div className="form-row">
                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikProcuradorNome"
                    >
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        type="text"
                        name="procurador.nome"
                        value={values.procurador?.nome || undefined}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                        isInvalid={!!errors.procurador?.nome}
                        isValid={
                          touched.procurador?.nome && !errors.procurador?.nome
                        }
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikProcuradorCPF"
                    >
                      <Form.Label>CPF</Form.Label>
                      <Form.Control
                        type="text"
                        name="procurador.cpf"
                        value={values.procurador?.cpf || undefined}
                        onChange={(e) => setFieldValue(
                          'procurador.cpf',
                          cpfMask(e.target.value),
                        )}
                        isValid={
                          touched.procurador?.cpf && !errors.procurador?.cpf
                        }
                        isInvalid={!!errors.procurador?.cpf}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikProcuradorRG"
                    >
                      <Form.Label>RG</Form.Label>
                      <Form.Control
                        type="text"
                        name="procurador.rg"
                        value={values.procurador?.rg || undefined}
                        onChange={handleChange}
                        isValid={
                          touched.procurador?.rg && !errors.procurador?.rg
                        }
                        isInvalid={!!errors.procurador?.rg}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikProcuradorProfissao"
                    >
                      <Form.Label>Profissão</Form.Label>
                      <Form.Control
                        type="text"
                        name="procurador.profissao"
                        value={values.procurador?.profissao || undefined}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                        isValid={
                          touched.procurador?.profissao
                          && !errors.procurador?.profissao
                        }
                        isInvalid={!!errors.procurador?.profissao}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikProcuradorIsEstrangeiro"
                    >
                      <Form.Label>Estrangeiro</Form.Label>
                      <Form.Select
                        aria-label="Selecione uma opção"
                        name="procurador.isEstrangeiro"
                        value={values.procurador?.isEstrangeiro || ''}
                        onChange={handleChange}
                        isValid={
                          touched.procurador?.isEstrangeiro
                          && !errors.procurador?.isEstrangeiro
                        }
                        isInvalid={!!errors.procurador?.isEstrangeiro}
                        onBlur={handleBlur}
                      >
                        <option>Selecione uma opção</option>
                        <option value="0">Não</option>
                        <option value="1">Sim</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikProcuradorEstadoCivil"
                    >
                      <Form.Label>Estado Civil</Form.Label>
                      <Form.Select
                        aria-label="Selecione uma opção"
                        name="procurador.estadoCivil"
                        value={values.procurador?.estadoCivil || ''}
                        onChange={handleChange}
                        isValid={
                          touched.procurador?.estadoCivil
                          && !errors.procurador?.estadoCivil
                        }
                        isInvalid={!!errors.procurador?.estadoCivil}
                        onBlur={handleBlur}
                      >
                        <option>Selecione uma opção</option>
                        <option value="1">Solteiro</option>
                        <option value="2">Casado</option>
                        <option value="3">Separado</option>
                        <option value="4">Divorciado</option>
                        <option value="5">Viúvo</option>
                      </Form.Select>
                    </Form.Group>
                  </div>
                  <div className="form-row">
                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikProcuradorCep"
                    >
                      <Form.Label>CEP</Form.Label>
                      <Form.Control
                        type="text"
                        name="procurador.cep"
                        value={values.procurador?.cep || undefined}
                        onChange={async (e) => {
                          const cepCode = e.target?.value?.replace(
                            specialCharacters,
                            '',
                          );
                          if (cepCode.length === 8) await searchCepProcurador(cepCode, setFieldValue);
                          setFieldValue('procurador.cep', cepCode);
                        }}
                        isValid={
                          touched.procurador?.cep && !errors.procurador?.cep
                        }
                        isInvalid={!!errors.procurador?.cep}
                        onBlur={handleBlur}
                      />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikProcuradorLogradouro"
                    >
                      <Form.Label>Logradouro</Form.Label>
                      <Form.Control
                        type="text"
                        name="procurador.logradouro"
                        value={values.procurador?.logradouro || undefined}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                        isValid={
                          touched.procurador?.logradouro
                          && !errors.procurador?.logradouro
                        }
                        isInvalid={!!errors.procurador?.logradouro}
                        onBlur={handleBlur}
                      />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikProcuradorNumero"
                    >
                      <Form.Label>Numero</Form.Label>
                      <Form.Control
                        type="text"
                        name="procurador.numero"
                        value={values.procurador?.numero || undefined}
                        onChange={handleChange}
                        isValid={
                          touched.procurador?.numero
                          && !errors.procurador?.numero
                        }
                        isInvalid={!!errors.procurador?.numero}
                        onBlur={handleBlur}
                      />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikProcuradorBairro"
                    >
                      <Form.Label>Bairro</Form.Label>
                      <Form.Control
                        type="text"
                        name="procurador.bairro"
                        value={values.procurador?.bairro || undefined}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                        isValid={
                          touched.procurador?.bairro
                          && !errors.procurador?.bairro
                        }
                        isInvalid={!!errors.procurador?.bairro}
                        onBlur={handleBlur}
                      />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikProcuradorCidade"
                    >
                      <Form.Label>Cidade</Form.Label>
                      <Form.Control
                        type="text"
                        name="procurador.cidade"
                        value={values.procurador?.cidade || undefined}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                        isValid={
                          touched.procurador?.cidade
                          && !errors.procurador?.cidade
                        }
                        isInvalid={!!errors.procurador?.cidade}
                        onBlur={handleBlur}
                      />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikEstado"
                    >
                      <Form.Label>Estado</Form.Label>
                      <Form.Select
                        aria-label="Selecione uma opção"
                        name="procurador.estado"
                        value={values.procurador?.estado || ''}
                        onChange={handleChange}
                        isValid={
                          touched.procurador?.estado
                          && !errors.procurador?.estado
                        }
                        isInvalid={!!errors.procurador?.estado}
                        onBlur={handleBlur}
                      >
                        <option>Selecione uma opção</option>
                        {Object.entries(estados).map((estado) => (
                          <option key={estado[1]} value={estado[1]}>
                            {estado[0]}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
              </div>

              <div className="card shadow mb-4">
                <div className="card-header">
                  <p className="mt-4">DADOS DO PAGAMENTO</p>
                  <div className="form-row">
                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikPagamentoBancoId"
                    >
                      <Form.Label>Banco</Form.Label>
                      <Form.Select
                        aria-label="Selecione uma opção"
                        name="pagamentoBancoId"
                        value={values.pagamentoBancoId || ''}
                        onChange={handleChange}
                        isValid={
                          touched.pagamentoBancoId && !errors.pagamentoBancoId
                        }
                        isInvalid={!!errors.pagamentoBancoId}
                        onBlur={handleBlur}
                      >
                        <option>Selecione uma opção</option>
                        {bancos?.map((item) => (
                          <option
                            key={item.id}
                            value={item.id}
                            selected={item.id === values.pagamentoBancoId}
                          >
                            {item.nome}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikPagamentoEndereco"
                    >
                      <Form.Label>Endereço</Form.Label>
                      <Form.Control
                        type="text"
                        name="pagamentoEndereco"
                        value={values.pagamentoEndereco || undefined}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                        isValid={
                          touched.pagamentoEndereco && !errors.pagamentoEndereco
                        }
                        isInvalid={!!errors.pagamentoEndereco}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikPagamentoAcompanhanteId"
                    >
                      <Form.Label>Acompanhante</Form.Label>
                      <Form.Select
                        aria-label="Selecione uma opção"
                        name="pagamentoAcompanhanteId"
                        value={values.pagamentoAcompanhanteId || ''}
                        onChange={handleChange}
                        isValid={
                          touched.pagamentoAcompanhanteId
                          && !errors.pagamentoAcompanhanteId
                        }
                        isInvalid={!!errors.pagamentoAcompanhanteId}
                        onBlur={handleBlur}
                      >
                        <option>Selecione uma opção</option>
                        {atendentes?.atendentes?.map((item) => (
                          <option
                            key={item.id}
                            value={item.id}
                            selected={
                              item.id === values.pagamentoAcompanhanteId
                            }
                          >
                            {item.nome}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>

                    <Form.Group
                      className="col-xl-2 col-md-6 mb-4"
                      controlId="validationFormikNumeroBeneficioPagamento"
                    >
                      <Form.Label>Número do Benefício</Form.Label>
                      <Form.Control
                        type="text"
                        name="pagamentoNumeroBeneficio"
                        value={values.pagamentoNumeroBeneficio || ''}
                        onChange={handleChange}
                        isValid={
                          touched.pagamentoNumeroBeneficio
                          && !errors.pagamentoNumeroBeneficio
                        }
                        isInvalid={!!errors.pagamentoNumeroBeneficio}
                        onBlur={handleBlur}
                        autoComplete="off"
                      />
                      <Form.Control.Feedback />
                    </Form.Group>

                    <Form.Group
                      className="col-xl-4 col-md-6 mb-4"
                      controlId="validationFormikPagamentoObservacao"
                    >
                      <Form.Label>Observação</Form.Label>
                      <Form.Control
                        type="text"
                        name="pagamentoObservacao"
                        value={values.pagamentoObservacao || undefined}
                        onChange={(e) => {
                          e.target.value = e.target.value.toUpperCase();
                          handleChange(e);
                        }}
                        isValid={
                          touched.pagamentoObservacao
                          && !errors.pagamentoObservacao
                        }
                        isInvalid={!!errors.pagamentoObservacao}
                        onBlur={handleBlur}
                      />
                      <Form.Control.Feedback />
                    </Form.Group>
                  </div>
                </div>
              </div>

              {isDadosComplementares && (
                <div className="card shadow mb-4">
                  <div className="card-header">
                    <p className="mt-4">Contrato</p>
                    <div className="form-row">
                      <Form.Group
                        className="col-xl-2 col-md-6 mb-4"
                        controlId="validationFormikContrato"
                      >
                        <Form.Label>Assinou o Contrato?</Form.Label>
                        <Form.Select
                          name="assinouContrato"
                          disabled={
                            perfilMaster !== localStorage.getItem('perfilId')
                            }
                          value={values.assinouContrato || ''}
                          onChange={(e) => {
                            const newDate = new Date();
                            setFieldValue('assinouContratoAt', newDate);
                            setAssinouContratoAt(newDate?.toISOString());
                            handleChange(e);
                          }}
                        >
                          <option value="">Não Definido</option>
                          <option value="N">Não</option>
                          <option value="S">Sim</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group
                        className="col-xl-2 col-md-6 mb-4"
                        controlId="validationFormikContratoObservacao"
                      >
                        <Form.Label>Observação Contrato</Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={1}
                          type="text"
                          name="observacaoContrato"
                          value={values.observacaoContrato || ''}
                          onChange={handleChange}
                          isValid={
                            touched.observacaoContrato
                            && !errors.observacaoContrato
                          }
                          isInvalid={!!errors.observacaoContrato}
                          onBlur={handleBlur}
                        />
                      </Form.Group>
                      {values.assinouContrato === 'S' && (
                        <Form.Group
                          className="col-xl-6 col-md-5 mb-3"
                          controlId="validationFormikAssinouContratoAt"
                        >
                          <Form.Label>Data que assinou o Contrato?</Form.Label>
                          <br />
                          <DatePicker
                            onChange={(newDate) => {
                              setFieldValue('assinouContratoAt', newDate);
                              setAssinouContratoAt(newDate?.toISOString());
                            }}
                            selected={
                              values.assinouContratoAt
                                ? new Date(values.assinouContratoAt)
                                : null
                            }
                            dateFormat="dd-MM-yyyy HH:mm"
                            showTimeSelect
                            locale={ptBR}
                            timeIntervals={15}
                            className={`form-control ${
                              !values.assinouContrato ? 'is-invalid' : ''
                            }`}
                            id="fimDate"
                            placeholderText="Selecione"
                            autoComplete="off"
                            disabled={
                              perfilMaster !== localStorage.getItem('perfilId')
                            }
                          />
                        </Form.Group>
                      )}
                    </div>
                  </div>
                </div>
              )}

              <div className="card shadow mb-4">
                <div className="card-header">
                  <p className="mt-4">ADVOGADO / REPRESENTANTE</p>
                  <div className="row">
                    <Form.Group
                      className="col-xl-12 col-md-6 mb-4"
                      controlId="validationFormikAdvogado"
                    >
                      <Form.Label>Advogado</Form.Label>
                      <Form.Select
                        aria-label="Selecione uma opção"
                        name="advogadoId"
                        value={values.advogadoId || ''}
                        onChange={handleChange}
                        isValid={touched.advogadoId && !errors.advogadoId}
                        isInvalid={!!errors.advogadoId}
                        onBlur={handleBlur}
                      >
                        <option>Selecione uma opção</option>
                        {advogados?.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.nome}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
              </div>

              <div className="card shadow mb-4">
                <div className="card-header">
                  {!isDadosComplementares && (
                    <>
                      <p className="mt-4">CONTRATO</p>
                      <div className="row">
                        <Form.Group
                          className="col-xl-2 col-md-3 mb-3"
                          controlId="validationFormikAssinouContrato"
                        >
                          <Form.Label>Assinou o Contrato?</Form.Label>
                          <Form.Select
                            name="assinouContrato"
                            value={values.assinouContrato}
                            onChange={(e) => {
                              const newDate = new Date();
                              setFieldValue('assinouContratoAt', newDate);
                              setAssinouContratoAt(newDate?.toISOString());
                              handleChange(e);
                            }}
                            isValid={
                              touched.assinouContrato && !errors.assinouContrato
                            }
                            isInvalid={!!errors.assinouContrato}
                            onBlur={handleBlur}
                          >
                            <option value="N">Não</option>
                            <option value="S">Sim</option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group
                          className="col-xl-4 col-md-4 mb-3"
                          controlId="validationFormikContratoObservacao"
                        >
                          <Form.Label>Observação</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={1}
                            type="text"
                            name="observacaoContrato"
                            value={values.observacaoContrato || ''}
                            onChange={handleChange}
                            isValid={
                              touched.observacaoContrato
                              && !errors.observacaoContrato
                            }
                            isInvalid={!!errors.observacaoContrato}
                            onBlur={handleBlur}
                          />
                        </Form.Group>

                        {values.assinouContrato === 'S' && (
                          <Form.Group
                            className="col-xl-6 col-md-5 mb-3"
                            controlId="validationFormikAssinouContratoAt"
                          >
                            <Form.Label>
                              Data que assinou o Contrato?
                            </Form.Label>
                            <br />
                            <DatePicker
                              onChange={(newDate) => {
                                setFieldValue('assinouContratoAt', newDate);
                                setAssinouContratoAt(newDate?.toISOString());
                              }}
                              selected={
                                values.assinouContratoAt
                                  ? new Date(values.assinouContratoAt)
                                  : null
                              }
                              dateFormat="dd-MM-yyyy HH:mm"
                              showTimeSelect
                              locale={ptBR}
                              timeIntervals={15}
                              className={`form-control ${
                                !values.assinouContratoAt ? 'is-invalid' : ''
                              }`}
                              id="fimDate"
                              placeholderText="Selecione"
                              autoComplete="off"
                              required
                            />
                          </Form.Group>
                        )}
                      </div>
                    </>
                  )}
                </div>

                <Button className="col-xl-12 col-md-6 mb-4" type="submit">
                  {spinnerSalvar ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    >
                      <span className="sr-only">Salvando...</span>
                    </Spinner>
                  ) : (
                    'Salvar dados Complementares'
                  )}
                </Button>
              </div>
            </fieldset>
          </Form>
        )}
      </Formik>

      <ChangeAlert
        dirtyCalledTimes={dirtyCalledTimes}
        showChangeAlert={showChangeAlert}
        setShowChangeAlert={setShowChangeAlert}
      />

      {alertError.isVisible && (
        <div className="fixed-top mt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-6">
                <Alert
                  variant="danger"
                  onClose={() => setAlertError({ isVisible: false })}
                  dismissible
                >
                  <p className="text-center mt-3">{alertError.message}</p>
                </Alert>
              </div>
            </div>
          </div>
        </div>
      )}
      {alertSuccess.isVisible && (
        <div className="fixed-top mt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-6">
                <Alert
                  variant="success"
                  onClose={() => setAlertSuccess({ isVisible: false })}
                  dismissible
                >
                  <p className="text-center mt-3">{alertSuccess.message}</p>
                </Alert>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

AtendimentoForm.defaultProps = {
  isAtendimentoEncerrado: false,
  isDadosComplementares: false,
  updateAllowedFinalizarAtendimento: (e) => e,
  enableFormularios: (e) => e,
  getUser: (e) => e,
};

AtendimentoForm.propTypes = {
  updateAllowedFinalizarAtendimento: PropTypes.func,
  enableFormularios: PropTypes.func,
  getUser: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  pessoa: PropTypes.object.isRequired,
  isAtendimentoEncerrado: PropTypes.bool,
  isDadosComplementares: PropTypes.bool,
};
