import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { ListGroup, Badge, Spinner } from 'react-bootstrap';

import api from '../../../../config/configApi';

export default function RelatorioContratosAssinados({
  startDate,
  endDate,
  unidadeId,
  unidadeNome,
}) {
  const [loading, setLoading] = useState(true);
  const [alertError, setAlertError] = useState({ isVisible: false });
  const [relatorio, setRelatorio] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const headers = {
        headers: {
          'Content-Type': 'application/json',
        },
      };
      try {
        const unidadeQuery = unidadeId ? `&unidadeId=${unidadeId}` : '';
        const { data } = await api.get(`/atendimentos-presenciais/relatorio-fechamento/fechamento-assinaram-contrato?dataInicio=${startDate.toLocaleDateString('pt-BR').split('/').reverse().join('-') || ''}&dataFim=${endDate.toLocaleDateString('pt-BR').split('/').reverse().join('-') || ''}${unidadeQuery}`, {}, headers);

        setRelatorio(data?.report?.[0]);
        console.log(data?.report?.[0]);
      } catch (err) {
        setRelatorio(null);
        setAlertError({
          isVisible: true,
          message: err.message || 'Não foi possível carregar o relatório. Por favor, tente novamente ou entre em contato com o suporte.',
        });
        setTimeout(() => {
          setAlertError({ isVisible: false, message: '' });
        }, 5000);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [startDate, endDate, unidadeId]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center my-3">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Carregando...</span>
        </Spinner>
      </div>
    );
  }

  if (alertError.isVisible) return <p>{alertError.message}</p>;

  if (!relatorio) return <p>Nenhum dado disponível.</p>;

  return (
    <div className="card shadow-lg p-4">
      <h4 className="text-danger text-center mb-4">TELA EM TESTE!</h4>
      <h4 className="mb-3 text-primary">
        Fechamento - &nbsp;
        {unidadeNome}
      </h4>
      <p className="mb-3">
        <strong>📅 Período: </strong>
        {format(startDate, 'dd/MM/yyyy', { locale: ptBR })}
        &nbsp;até&nbsp;
        {format(endDate, 'dd/MM/yyyy', { locale: ptBR })}
      </p>

      <div className="mb-3">
        <p>
          <strong>Vieram:</strong>
          <span className>
            {relatorio.countAtendimentos}
          </span>
        </p>
        <p>
          <strong>Não Vieram:</strong>
          <span className>
            {relatorio.countAtendimentosNaoRealizados}
          </span>
        </p>
        <p>
          <strong>Assinaram:</strong>
          <span className>
            {relatorio.countAssinaramContrato}
          </span>
        </p>
        <p>
          <strong>Não Assinaram:</strong>
          <span className>
            {relatorio.countNaoAssinaramContrato}
          </span>
        </p>
      </div>

      <h5 className="text-secondary mt-4">Tipos de Benefícios</h5>
      <ul className="list-group mb-3">
        {relatorio?.countPessoasAtendidasByBeneficio?.map((beneficio) => (
          <li key={beneficio.beneficio} className="list-group-item d-flex justify-content-between align-items-center">
            {beneficio.beneficio || 'O campo não foi preenchido'}
            <span className="badge bg-light text-dark">{beneficio.count}</span>
          </li>
        ))}
      </ul>

      <h5 className="text-secondary mt-4">Com CRAS ou Sem CRAS</h5>
      <ListGroup className="mb-3">
        <ListGroup.Item className="d-flex justify-content-between align-items-center">
          Com CRAS
          <Badge bg="light" text="dark">
            {relatorio.countPessoasComCras}
          </Badge>
        </ListGroup.Item>
        <ListGroup.Item className="d-flex justify-content-between align-items-center">
          Sem CRAS
          <Badge bg="light" text="dark">
            {relatorio.countPessoasSemCras}
          </Badge>
        </ListGroup.Item>
      </ListGroup>

      <h5 className="text-danger mt-4">Motivos para Não Assinatura</h5>
      <ul className="list-group mb-3">
        {relatorio?.countObservacaoNaoAssinouContrato?.map((obs) => (
          <li key={obs.observacaoContrato} className="list-group-item d-flex justify-content-between align-items-center">
            {obs.observacaoContrato || 'O campo não foi preenchido'}
            <span className="badge bg-light text-dark">{obs.count}</span>
          </li>
        ))}
      </ul>
      <h5 className="text-primary">🔍 Indicações</h5>
      <ul className="list-group">
        {relatorio?.countPessoasAtendidasByIndicacao?.map((indicacao) => (
          <li key={indicacao.indicacao} className="list-group-item d-flex justify-content-between align-items-center">
            {indicacao.indicacao}
            <span className="badge bg-light text-dark">{indicacao.count}</span>
          </li>
        ))}
      </ul>
      <footer className="mt-4 text-muted">
        Para gerar este relatório, foram considerados as seguintes informações:
        <ul>
          <li>
            Data que a pessoa assinou o contrato (dados complementares).
          </li>
          <li>
            Data em que um atendimento foi realizado.
          </li>
        </ul>
      </footer>
    </div>
  );
}

RelatorioContratosAssinados.propTypes = {
  startDate: PropTypes.isRequired,
  endDate: PropTypes.isRequired,
  unidadeId: PropTypes.number.isRequired,
  unidadeNome: PropTypes.string.isRequired,
};
