import PropTypes from 'prop-types';
import Select from 'react-select';
import React, { useState, useEffect, useRef } from 'react';
import {
  Alert, Form, Card,
} from 'react-bootstrap';
import api from '../../../config/configApi';
import EmptyState from '../../Shared/EmptyState';
import { formatToBRL, formatBRLToFloat } from '../../../utils/generic';
import ModalRecebimento from './ModalRecebimento';
import ExportToExcel from './ExportToExcel';

export default function Recebimento({ pessoaId }) {
  const [amount, setAmount] = useState('');
  const [metodoDePagamento, setMetodoDePagamento] = useState();
  const [metodosDePagamento, setMetodosDePagamento] = useState([]);
  const [pagamento, setPagamento] = useState([]);
  const [paymentCategory, setPaymentCategory] = useState();
  const [status, setStatus] = useState();
  const [obs, setObs] = useState();
  const [dueDate, setDueDate] = useState();
  const [paidAt, setPaidAt] = useState();
  const [trackingNumber, setTrackingNumber] = useState();
  const [advogadoId, setAdvogadoId] = useState();
  const [advogados, setAdvogados] = useState([]);
  const [barCode, setBarCode] = useState();
  const [filesUrl, setFilesUrl] = useState();
  const [reviewedById, setreviewedById] = useState();
  const [recordedById, setRecordedById] = useState();
  const [alertSuccess, setAlertSuccess] = useState({ isVisible: false, message: '' });
  const [alertError, setAlertError] = useState({ isVisible: false });
  const [loading, setLoading] = useState(false);
  const [atendentes, setAtendentes] = useState([]);

  const editarButtonsRef = useRef([]);

  const getMetodosPagamento = async () => {
    try {
      const res = await api.get('/metodos-de-pagamento');
      setMetodosDePagamento(res.data.metodosDePagamento);
    } catch (error) {
      console.error('Erro ao buscar os tipos de pagamento:', error);
    }
  };

  const getPagamento = async () => {
    try {
      const res = await api.get(`pessoa/${pessoaId}/pagamento`);
      setPagamento(res.data.pagamento);
    } catch (error) {
      setAlertError({
        isVisible: true,
        message: 'Falha ao buscar o a. Por favor tente novamente.',
      });
      window.setTimeout(() => {
        setAlertError({ isVisible: false });
      }, 5000);
    }
  };

  const getAdvogados = async () => {
    try {
      const res = await api.get('/advogados');
      setAdvogados(res.data.advogados);
    } catch (error) {
      console.error('Erro ao buscar advogados:', error);
    }
  };

  const getAtendentes = async () => {
    await api
      .get('/user/atendentes')
      .then((response) => {
        setAtendentes(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getAtendentes();
  }, []);

  const handleButtonosRefClick = (index) => {
    if (editarButtonsRef.current[index]) {
      editarButtonsRef.current[index].click();
    }
  };
  useEffect(() => {
    getAtendentes();
  }, []);

  const options = atendentes?.atendentes
    ? atendentes.atendentes.map((item) => ({
      value: item.id,
      label: item.nome,
    }))
    : [];
  const getBadgeClass = (paymentStatus) => {
    if (paymentStatus === 'pago') return 'bg-success';
    if (paymentStatus === 'em-aberto') return 'bg-warning';
    if (paymentStatus === 'em-atraso') return 'bg-danger';
    return '';
  };

  useEffect(() => {
    getMetodosPagamento();
    getPagamento();
    getAdvogados();
  }, []);

  const saveRecebimento = async (e) => {
    e.preventDefault();
    setLoading(true);
    const pagamentoData = {
      amount: formatBRLToFloat(amount),
      paymentCategory,
      metodoPagamentoId: metodoDePagamento,
      dueDate,
      status,
      trackingNumber,
      paidAt,
      advogadoId,
      barCode,
      reviewedById,
      filesUrl,
      recordedById,
      obs,
    };
    try {
      const response = await api.post(`pessoa/${pessoaId}/pagamento`, pagamentoData);
      getPagamento();
      console.log('Pagamento salvo com sucesso:', response.data);
      setAlertSuccess({
        isVisible: true,
        message: 'Pagamento salvo com sucesso!',
      });
    } catch (error) {
      console.error('Erro ao salvar pagamento:', error);
      setAlertError({
        isVisible: true,
        message: 'Falha ao salvar pagamento. Por favor, tente novamente.',
      });
      setTimeout(() => {
        setAlertError({ isVisible: false });
      }, 5000);
    } finally {
      setLoading(false);
    }
  };
  const formatDataForExport = () => (
    pagamento?.map((item) => ({
      Valor: `R$ ${formatToBRL(item.amount?.toFixed(2))}`, // Formata o valor para BRL
      Categoria: item.paymentCategory,
      'Método de Pagamento':
        metodosDePagamento?.find((m) => m.id === item.metodoPagamentoId)?.nome
        || '',
      'Data de Vencimento': item.dueDate,
      Status: item.status,
      'Número de Rastreamento': item.trackingNumber,
      'Data de Pagamento': item.paidAt,
      Advogado: advogados?.find((a) => a.id === item.advogadoId)?.nome || '',
      'Código de Barras': item.barCode,
      Observação: item.obs,
      'Conferido Por': atendentes?.atendentes?.find((a) => a.id === item.reviewedById)?.nome || '',
      'Registrado Por': atendentes?.atendentes?.find((a) => a.id === item.recordedById)?.nome || '',
      'Link para Arquivos': item.filesUrl || '',
    }))
  );

  return (
    <>
      {alertSuccess.isVisible && (
        <Alert
          variant="success"
          onClose={() => setAlertSuccess({ isVisible: false, message: '' })}
          dismissible
          className="fixed-top mt-5 w-50 mx-auto"
        >
          <p className="text-center mt-3">{alertSuccess.message}</p>
        </Alert>
      )}
      {alertError.isVisible && (
        <Alert
          variant="danger"
          onClose={() => setAlertError({ isVisible: false, message: '' })}
          dismissible
          className="fixed-top mt-5 w-50 mx-auto"
        >
          <p className="text-center mt-3">{alertError.message}</p>
        </Alert>
      )}
      <Card className="mb-4">
        <Card.Body>
          <div className="row align-items-start mb-3">
            <div className="col-xl-2 col-md-6 mb-1">
              <label>Valor Total*</label>
              <div className="input-group">
                <span className="input-group-text">R$</span>
                <Form.Control
                  type="text"
                  name="amount"
                  placeholder="Digite o valor"
                  className="form-control"
                  value={formatToBRL(amount)}
                  onChange={((e) => {
                    setAmount(formatToBRL(e.target.value));
                  })}
                  isInvalid={amount === '0,00' || !amount}
                />
                {(amount === '0,00' || !amount) && (
                  <Form.Control.Feedback type="invalid">
                    O valor do pagamento é obrigatório
                  </Form.Control.Feedback>
                )}
              </div>
            </div>
            <div className="col-xl-3 col-md-6 mb-1">
              <Form.Group controlId="validationFormikPaymentCategory">
                <Form.Label>Categoria do Pagamento*</Form.Label>
                <Form.Select
                  aria-label="Selecione uma categoria de pagamento"
                  name="paymentCategory"
                  value={paymentCategory}
                  onChange={(e) => setPaymentCategory(e.target.value)}
                  isInvalid={!paymentCategory || paymentCategory === 'Selecione'}
                >
                  <option value="Selecione">Selecione</option>
                  <option value="Entrada">Entrada</option>
                  <option value="Desconto">Desconto</option>
                  <option value="Parcela 1">Parcela 1</option>
                  <option value="Parcela 2">Parcela 2</option>
                  <option value="Parcela 3">Parcela 3</option>
                  <option value="Parcela 4">Parcela 4</option>
                  <option value="Parcela 5">Parcela 5</option>
                  <option value="Parcela 6">Parcela 6</option>
                  <option value="Parcela 7">Parcela 7</option>
                  <option value="Parcela 8">Parcela 8</option>
                  <option value="Parcela 9">Parcela 9</option>
                  <option value="Parcela 10">Parcela 10</option>
                  <option value="Parcela 11">Parcela 11</option>
                  <option value="Parcela 12">Parcela 12</option>
                </Form.Select>
                {(!paymentCategory || paymentCategory === 'Selecione') && (
                  <Form.Control.Feedback type="invalid">
                    O valor da categoria de pagamento é obrigatório
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>

            <div className="col-xl-3 col-md-6 mb-1">
              <Form.Group controlId="validationFormikMetodoDePagamento">
                <Form.Label>Tipo de Pagamento*</Form.Label>
                <Form.Select
                  aria-label="Selecione o tipo de pagamento"
                  name="metodoDePagamento"
                  value={metodoDePagamento}
                  onChange={(e) => setMetodoDePagamento(e.target.value)}
                  isInvalid={!metodoDePagamento || metodoDePagamento === 'selecione'}
                >
                  <option value="selecione">Selecione</option>
                  {metodosDePagamento?.map((metodoPagamento) => (
                    <option key={metodoPagamento.id} value={metodoPagamento.id}>
                      {metodoPagamento.nome}
                    </option>
                  ))}
                </Form.Select>
                {(!metodoDePagamento || metodoDePagamento === 'selecione') && (
                  <Form.Control.Feedback type="invalid">
                    O tipo de pagamento é obrigatório
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </div>

            <div className="col-xl-2 col-md-6 mb-3">
              <label>Data de Vencimento*</label>
              <Form.Control
                type="date"
                name="dueDate"
                className="form-control"
                value={dueDate}
                onChange={((e) => {
                  setDueDate(e.target.value);
                })}
                isInvalid={!dueDate}
              />
              {!dueDate && (
                <Form.Control.Feedback type="invalid">
                  A data de vencimento é obrigatória
                </Form.Control.Feedback>
              )}
            </div>
            <div className="col-xl-2 col-md-6 mb-3">
              <Form.Group controlId="validationFormikStatus">
                <Form.Label>Status do Pagamento*</Form.Label>
                <Form.Select
                  aria-label="Selecione o status do pagamento"
                  name="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="selecione">Selecione</option>
                  <option value="em-aberto">Em Aberto</option>
                  <option value="pago">Pago</option>
                  <option value="em-atraso">Em Atraso</option>
                </Form.Select>
              </Form.Group>
            </div>

            <div className="col-xl-2 col-md-6 mb-3">
              <label>Nº de Controle</label>
              <input
                type="text"
                name="trackingNumber"
                placeholder="Digite o nº de controle"
                className="form-control"
                value={trackingNumber}
                onChange={((e) => {
                  setTrackingNumber(e.target.value);
                })}
              />
            </div>

            <div className="col-xl-2 col-md-6 mb-3">
              <label>Data de Pagamento</label>
              <input
                type="date"
                name="paidAt"
                className="form-control"
                value={paidAt}
                onChange={((e) => {
                  setPaidAt(e.target.value);
                })}
                required
              />
            </div>

            <div className="col-xl-3 col-md-6 mb-3">
              <label>Observações</label>
              <textarea
                name="obs"
                className="form-control"
                rows="1"
                value={obs}
                onChange={((e) => {
                  setObs(e.target.value);
                })}
              />
            </div>
            <div className="col-xl-3 col-md-6 mb-3">
              <Form.Group controlId="validationFormikAdvogado">
                <Form.Label>Advogado</Form.Label>
                <Form.Select
                  aria-label="Selecione um advogado"
                  name="advogadoId"
                  value={advogadoId || ''}
                  onChange={(e) => setAdvogadoId(e.target.value)}
                >
                  <option value="">Selecione uma opção</option>
                  {advogados?.map((advogado) => (
                    <option key={advogado.id} value={advogado.id}>
                      {advogado.nome}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </div>
            <div className="col-xl-2 col-md-6 mb-3">
              <label>Códigos de Barras</label>
              <input
                type="text"
                name="barCode"
                placeholder="Digite o nº do código de barras"
                className="form-control"
                value={barCode}
                onChange={((e) => {
                  setBarCode(e.target.value);
                })}
              />
            </div>
          </div>

          <div className="row align-items-start mb-3">
            <div className="col-xl-3 col-md-6 mb-4">
              <label htmlFor="reviewedById">Conferido Por</label>
              <Select
                name="reviewedById"
                id="reviewedById"
                placeholder="Conferido por"
                defaultValue={options?.find((option) => option.value === reviewedById) || null}
                onChange={(selectedOption) => {
                  console.log('Selecionado:', selectedOption);
                  setreviewedById(selectedOption?.value || '');
                }}
                options={options}
                isSearchable
                isClearable
              />
            </div>
            <div className="col-xl-2 col-md-6 mb-3">
              <label>Link para Arquivos</label>
              <input
                type="text"
                name="filesUrl"
                placeholder="recibos, notas, docs."
                className="form-control"
                value={filesUrl}
                onChange={((e) => {
                  setFilesUrl(e.target.value);
                })}
              />
            </div>

            <div className="col-xl-3 col-md-6 mb-4">
              <label htmlFor="recordedById">Registrado Por</label>
              <Select
                name="recordedById"
                id="recordedById"
                placeholder="Registrado por"
                defaultValue={options?.find((option) => option.value === recordedById) || null}
                onChange={(selectedOption) => {
                  setRecordedById(selectedOption?.value || '');
                }}
                options={options}
                isSearchable
                isClearable
              />
            </div>

            <div className="col-xl-2 col-md-6 mb-1">
              <label>&nbsp;</label>
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={saveRecebimento}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    />
                    Salvando...
                  </>
                ) : (
                  'Salvar'
                )}
              </button>
            </div>
            <div className="col-xl-2 col-md-6 mb-1" style={{ marginTop: '32px' }}>
              <ExportToExcel data={formatDataForExport()} fileName="Recebimentos" />
            </div>
          </div>
        </Card.Body>
      </Card>
      <div className="table-responsive">
        <div className="card-body">
          <table className="table table-sm table-light w-100">
            <thead>
              <tr>
                <th hidden>ID</th>
                <th className="small fw-bold">Valor do Pagamento</th>
                <th className="small fw-bold">Categoria Pagamento</th>
                <th className="small fw-bold">Status</th>
                <th className="small fw-bold">Método de Pagamento</th>
                <th className="small fw-bold">Nº de Controle</th>
                <th className="small fw-bold">Vence Em</th>
                <th className="small fw-bold">Pago em</th>
                <th className="small fw-bold">Advogado</th>
                <th className="small fw-bold">Código de Barras</th>
                <th className="small fw-bold">Conferido Por</th>
                <th className="small fw-bold">Link Arquivos</th>
                <th className="small fw-bold">Registrado Por</th>
                <th className="small fw-bold">Obs</th>
                <th className="small fw-bold">Ações</th>
              </tr>
            </thead>
            <tbody>
              {pagamento?.map((item, index) => (
                <tr key={item.id}>
                  <td hidden>{item.id}</td>
                  <td className="align-middle small">
                    <button
                      type="button"
                      onClick={() => handleButtonosRefClick(index)}
                      className="btn btn-link p-0"
                    >
                      {formatToBRL(parseFloat(item.amount).toFixed(2))}
                    </button>

                  </td>
                  <td className="align-middle small">{item.paymentCategory || 'Categoria não informada'}</td>
                  <td className="align-middle small">
                    <span
                      className={`badge ${getBadgeClass(item.status?.toLowerCase())}`}
                    >
                      {item.status || 'Status desconhecido'}
                    </span>
                  </td>
                  <td className="align-middle small">{item.metodopagamento?.nome || 'Método não informado'}</td>
                  <td className="align-middle small">{item.trackingNumber || 'Não informado'}</td>
                  <td className="align-middle small">
                    {item.dueDate
                      ? new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(new Date(item.dueDate))
                      : 'Sem vencimento'}
                  </td>
                  <td className="align-middle small">
                    {item.paidAt
                      ? new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(new Date(item.paidAt))
                      : 'Não pago'}
                  </td>
                  <td className="align-middle small">
                    {item.advogado?.nome || 'Advogado não informado'}
                  </td>
                  <td className="align-middle small">{item.barCode || 'Não disponível'}</td>

                  <td className="align-middle small">
                    {item.reviewer?.nome || 'não informado'}
                  </td>

                  <td className="align-middle small">
                    {item.filesUrl ? (
                      <a href={item.filesUrl} target="_blank" rel="noreferrer">
                        {item.filesUrl}
                      </a>
                    ) : (
                      'Nenhum link'
                    )}
                  </td>
                  <td className="align-middle small">
                    {item.recorder?.nome || 'não informado'}
                  </td>
                  <td className="align-middle small">{item.obs || 'Sem observações'}</td>

                  <td className="align-middle">
                    <ModalRecebimento
                      itemPagamento={item}
                      metodosDePagamento={metodosDePagamento}
                      advogados={advogados}
                      atendentes={atendentes}
                      editarButtonRef={(el) => {
                        editarButtonsRef.current[index] = el;
                        return el;
                      }}
                      setPagamento={setPagamento}
                      itemIndex={index}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <EmptyState isActive={!pagamento?.length} />
      {alertError.isVisible && (
        <div className="fixed-top mt-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-6">
                <Alert
                  variant="danger"
                  onClose={() => setAlertError({ isVisible: false })}
                  dismissible
                >
                  <p className="text-center mt-3">{alertError.message}</p>
                </Alert>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

Recebimento.propTypes = {
  pessoaId: PropTypes.string.isRequired,
};
