/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DatePicker from 'react-datepicker';
import { faMagnifyingGlass, faFile } from '@fortawesome/free-solid-svg-icons';
import {
  Spinner, Dropdown, Badge,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import { addDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Navbar } from '../../../components/Navbar';
import { Sidebar } from '../../../components/Sidebar';
import { LogOutModal } from '../../../components/LogOutModal';
import api from '../../../config/configApi';
import TabelaAtendimentosPresenciais from './TabelaAtendimentosPresenciais';
import {
  disableButtonAction,
  enableButtonAction,
} from '../../../store/isButtonEnabled';

const atendimentoFiltroOptions = [{ id: 1, name: 'Não Atendido' }, { id: 2, name: 'Atendido' }];

export default function AtendimentosPresenciais() {
  const [setShowModal] = useState(false);

  const handleFilterButtonClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const [setFiltroAtivo] = useState(false);

  const [fimDate, setFimDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [oneDate, setOneDate] = useState(new Date(new Date().setHours(0, 0, 0, 0)));
  const [unidadesOptions, setUnidadesOptions] = useState(['']);
  const [pessoaNome, setPessoaNome] = useState('');
  const [unidadeId, setUnidadeId] = useState('');
  const [unidades, setUnidades] = useState([]);

  const [status, setStatus] = useState({
    type: '',
    mensagem: '',
  });
  const [origens, setOrigens] = useState([]);
  const [origemdasligacoes, setOrigemDasLigacoes] = useState('');
  const [beneficios, setBeneficios] = useState([]);
  const [setBeneficio] = useState('');

  const [atendidoSelect, setAtendidoSelect] = useState('');
  const [atendido, setAtendido] = useState(false);
  const [spinnerSearch, setSpinnerSearch] = useState();
  const datePickerFimDate = useRef(null);
  const [selectBeneficios, setSelectBeneficios] = useState([]);
  const [setButtonEnabled] = useState(false);

  const [atendimentosGroupedByBeneficios, setAtendimentosGroupedByBeneficios] = useState(false);

  const handleSelectChange = (selectedOptions) => {
    setSelectBeneficios(selectedOptions);
    setButtonEnabled(selectedOptions.length > 0);
  };
  const [contrato, setContrato] = useState('');

  const options = beneficios?.beneficios?.map((item) => ({
    value: item.nome,
    label: item.nome,
  }));

  const handleDateChange = (setter) => (date) => {
    if (!date) {
      setter(null);
    } else {
      const adjustedDate = new Date(date.setHours(0, 0, 0, 0));
      setter(adjustedDate);
    }
  };

  const [atendimentosPresenciais, setAtendimentosPresenciais] = useState([]);
  const { isButtonEnabled } = useSelector((state) => state);
  const [floatAlertSuccess, setFloatAlertSuccess] = useState({ isVisible: false });
  const [tiposAtendimento, setTiposAtendimento] = useState([]);
  const [tipoAtendimento, setTipoAtendimento] = useState('');
  const dispatch = useDispatch();

  const getAtendimentoPresencialParams = () => {
    let contratoAssinado;
    const agendadoParaDe = `${oneDate.getDate().toString().padStart(2, '0')}/${(oneDate.getMonth() + 1).toString().padStart(2, '0')}/${oneDate.getFullYear()}`;
    const agendadoParaAte = `${fimDate.getDate().toString().padStart(2, '0')}/${(fimDate.getMonth() + 1).toString().padStart(2, '0')}/${fimDate.getFullYear()}`;

    if (contrato === 'sim') {
      contratoAssinado = 'S';
    } else if (contrato === 'nao') {
      contratoAssinado = 'N';
    } else {
      contratoAssinado = '';
    }

    return {
      pessoaNome,
      unidadeId,
      agendadoParaDe,
      agendadoParaAte,
      origemdasligacoes,
      atendido,
      beneficio: selectBeneficios.map((option) => option.value).join(', '),
      tipoatendimentoId: tipoAtendimento,
      assinouContrato: contratoAssinado,
    };
  };

  const getTiposAtendimento = async () => {
    const res = await api.get('/tipos-de-atendimento');

    setTiposAtendimento(res.data?.tiposdeatendimento);
  };

  const getAtendimentosPresenciais = async (params) => {
    setSpinnerSearch(true);

    dispatch(disableButtonAction());
    try {
      const { data } = await api.get('/atendimentos-presenciais', {
        params,
      });

      setAtendimentosPresenciais(data.atendimentosPresenciais);
    } catch (error) {
      setAtendimentosPresenciais([]);
      setStatus({
        type: 'error',
        mensagem: 'Não foi possível carregar os atendimentos agendados',
      });
    }
    setSpinnerSearch(false);
    dispatch(enableButtonAction());
  };

  const showFloatAlertSuccess = (message) => {
    setFloatAlertSuccess({ isVisible: true, message });
  };

  const getOrigens = async () => {
    await api
      .get('/origem/origens')
      .then((response) => {
        setOrigens(response.data);
      })
      .catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };

  const getBeneficios = async () => {
    await api.get('/beneficio/beneficios')
      .then((response) => {
        setBeneficios(response.data);
        console.log(response.data);
      }).catch((err) => {
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
          });
        } else {
          setStatus({
            type: 'error',
            mensagem: 'Erro: Tente mais tarde!',
          });
        }
      });
  };
  useEffect(() => {
    getBeneficios();
    getTiposAtendimento();
  }, []);

  useEffect(() => {
    setAtendimentosGroupedByBeneficios(
      Object.groupBy(atendimentosPresenciais, (atendimento) => atendimento.pessoa.beneficio),
    );
  }, [atendimentosPresenciais]);

  const searchAgendamentos = async (event) => {
    if (event) {
      event.preventDefault();
    }
    const params = getAtendimentoPresencialParams();
    getAtendimentosPresenciais(params);
    setFiltroAtivo(true);
    setShowModal(false);
  };

  useEffect(() => {
    const getUnidades = async () => {
      try {
        const { data } = await api.get('/unidade/unidades', {
        });
        setUnidades(data.unidades);
        setUnidadesOptions(
          <>
            {data.unidades.map((unidade) => (
              <option value={unidade.id}>{unidade.nome}</option>
            ))}
          </>,
        );
        const agendadoParaDe = `${oneDate.getDate().toString().padStart(2, '0')}/${(oneDate.getMonth() + 1).toString().padStart(2, '0')}/${oneDate.getFullYear()}`;
        const agendadoParaAte = `${fimDate.getDate().toString().padStart(2, '0')}/${(fimDate.getMonth() + 1).toString().padStart(2, '0')}/${fimDate.getFullYear()}`;
        await getAtendimentosPresenciais(
          {
            unidadeId, tipoatendimentoId: tipoAtendimento, agendadoParaDe, agendadoParaAte,
          },
        );
      } catch (error) {
        setStatus({
          type: 'error',
          mensagem: 'Não foi possível carregar as unidades',
        });
      }
    };

    getUnidades();
    getOrigens();
  }, []);

  const [selectedBeneficios, setSelectedBeneficios] = useState([]);

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setSelectedBeneficios([...selectedBeneficios, value]);
    } else {
      setSelectedBeneficios(selectedBeneficios.filter((item) => item !== value));
    }
    setBeneficio([...selectedBeneficios, value].join(', '));
  };

  const exportarDisparo = async () => {
    dispatch(disableButtonAction());
    try {
      const params = getAtendimentoPresencialParams();
      const [dayDe, monthDe, yearDe] = params.agendadoParaDe.split('/');
      const [dayAte, monthAte, yearAte] = params.agendadoParaAte.split('/');
      const response = await api.get(
        '/atendimentos-presenciais/exportar-aviso',
        {
          params,
          responseType: 'blob',
        },
      );

      const fileName = `atendimentos_de_${dayDe}-${monthDe}-${yearDe}_ate_${dayAte}-${monthAte}-${yearAte}.xlsx`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setStatus({
        type: 'error',
        mensagem: 'Não foi possível exportar a pesquisa',
      });
    }
    dispatch(enableButtonAction());
  };

  const exportarPesquisa = async () => {
    dispatch(disableButtonAction());
    try {
      const params = getAtendimentoPresencialParams();
      const [dayDe, monthDe, yearDe] = params.agendadoParaDe.split('/');
      const [dayAte, monthAte, yearAte] = params.agendadoParaAte.split('/');
      const response = await api.get(
        '/atendimentos-presenciais/exportar-pesquisa',
        {
          params,
          responseType: 'blob',
        },
      );

      const fileName = `atendimentos_de_${dayDe}-${monthDe}-${yearDe}_ate_${dayAte}-${monthAte}-${yearAte}.xlsx`;

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.setAttribute('download', fileName);
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      setStatus({
        type: 'error',
        mensagem: 'Não foi possível exportar a pesquisa',
      });
    }
    dispatch(enableButtonAction());
  };

  return (
    <>
      {floatAlertSuccess.isVisible && (
        <div
          style={{
            position: 'fixed',
            top: 20,
            right: 20,
            zIndex: 1000, // Adjust the z-index to make sure it appears above other content
            width: '300px', // Adjust the width as needed
          }}
        >
          <Alert
            variant="success"
            onClose={() => setFloatAlertSuccess({ isVisible: false })}
            dismissible
          >
            <p className="text-center mt-3">{floatAlertSuccess.message}</p>
          </Alert>
        </div>
      )}
      <div id="wrapper">
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Navbar />
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                <h1 className="h3 mb-0 text-gray-800">Agendamentos</h1>
              </div>
              <hr />
              <div className="card shadow mb-4">
                <div className="card-header py-3">
                  <Form onSubmit={searchAgendamentos}>
                    <div className="form-row align-items-center">
                      <div className="row align-items-center">
                        <div className="col-lg-9 col-md-12">
                          <div className="row align-items-center">
                            <div className="col-lg-2 col-md-6">
                              <Form.Label className="sr-only">Nome</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="digite o nome"
                                onChange={(e) => {
                                  setPessoaNome(e.target.value);
                                }}
                              />
                            </div>

                            <div className="col-lg-3 col-md-6">
                              <Form.Select
                                aria-label="Default select example"
                                onChange={(e) => {
                                  setUnidadeId(e.target.value);
                                }}
                              >
                                <option value="">Todas as Unidades</option>
                                {unidadesOptions}
                              </Form.Select>
                            </div>
                            <div className="col-sm-2" style={{ display: 'flex', alignItems: 'center' }}>
                              <label htmlFor="oneDate" style={{ marginRight: '10px', paddingTop: '5px' }}>
                                de:
                              </label>
                              <DatePicker
                                selected={oneDate}
                                onChange={(date) => {
                                  handleDateChange(setOneDate)(date);
                                  datePickerFimDate.current.setOpen(true);
                                }}
                                selectsStart
                                startDate={oneDate}
                                endDate={fimDate}
                                dateFormat="dd-MM-yyyy"
                                locale={ptBR}
                                id="oneDate"
                                className="form-control"
                                style={{ marginTop: '5px' }}
                                placeholderText="Selecione a data"
                                autoComplete="off"
                                required
                              />
                            </div>
                            <div className="col-sm-2" style={{ display: 'flex', alignItems: 'center' }}>
                              <label htmlFor="fimDate" style={{ marginRight: '10px', paddingTop: '5px' }}>até:</label>
                              <DatePicker
                                selected={fimDate}
                                ref={datePickerFimDate}
                                onChange={(date) => handleDateChange(setFimDate)(date)}
                                selectsEnd
                                startDate={oneDate}
                                endDate={fimDate}
                                minDate={oneDate}
                                maxDate={addDays(oneDate, 31)}
                                dateFormat="dd-MM-yyyy"
                                locale={ptBR}
                                className="form-control"
                                id="fimDate"
                                style={{ marginTop: '5px' }}
                                placeholderText="Selecione"
                                autoComplete="off"
                                required
                              />
                            </div>
                            <div className="col-lg-3 col-md-6 d-flex align-items-center" style={{ marginTop: '5px' }}>
                              <Select
                                isMulti
                                options={options}
                                value={selectBeneficios}
                                onChange={handleSelectChange}
                                placeholder="Benefício"
                                className="col-lg-12 col-md-12"
                              />
                            </div>

                            <div className="col-lg-3 col-md-6">
                              <select
                                className="custom-select my-2 mr-sm-2"
                                name="naoatendido"
                                id="naoatendido"
                                value={atendidoSelect}
                                onChange={(e) => {
                                  setAtendido(e.target.value === 'Atendido');
                                  setAtendidoSelect(e.target.value);
                                }}
                              >
                                {atendimentoFiltroOptions.map((option) => (
                                  <option key={option.id} value={option.name}>
                                    {option.name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="col-lg-3 col-md-6">
                              <select
                                className="custom-select my-2 mr-sm-2"
                                name="origemdasligacoes"
                                id="origemdasligacoes"
                                placeholder="origemdasligacoes"
                                value={origemdasligacoes}
                                onChange={(text) => setOrigemDasLigacoes(text.target.value)}
                              >
                                <option value="">Todas as Origens</option>
                                {origens?.origens?.map((item) => (
                                  <option
                                    key={item.nome}
                                    value={item.nome}
                                    selected={item.nome === origemdasligacoes}
                                  >
                                    {item.nome}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <select
                                className="custom-select my-2 mr-sm-2"
                                name="tipoatendimento"
                                id="tipoatendimento"
                                value={tipoAtendimento}
                                onChange={(text) => setTipoAtendimento(text.target.value)}
                              >
                                <option value="">Todos os Tipos</option>
                                {tiposAtendimento?.map((ta) => (
                                  <option
                                    key={ta.id}
                                    value={ta.id}
                                    selected={ta.id === tipoAtendimento}
                                  >
                                    {ta.nome}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-lg-3 col-md-6">
                              <select
                                className="custom-select my-1 mr-sm-2"
                                name="contrato"
                                id="contrato"
                                placeholder="Contrato"
                                onChange={(e) => setContrato(e.target.value)}
                              >
                                <option value="" selected>
                                  Assinou o Contrato?
                                </option>
                                <option value="sim">Sim</option>
                                <option value="nao">Não</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-12">
                          <div className="row align-items-center">
                            <div className="col-md-6">
                              <div className="d-sm-flex align-items-center">
                                <div className="container" style={{ paddingTop: '-10px' }}>
                                  <div className="row justify-content-center">
                                    <Button
                                      variant="primary"
                                      type="submit"
                                      disabled={!isButtonEnabled}
                                      className="mr-5"
                                    >
                                      <span>
                                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                                      </span>
                                      &nbsp;Localizar
                                    </Button>
                                  </div>
                                </div>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant="secondary"
                                    id="dropdown-pessoa-arquivos"
                                    className="btn-block mr-5"
                                    disabled={!isButtonEnabled || atendido}
                                  >
                                    <FontAwesomeIcon icon={faFile} />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={exportarDisparo}
                                    >
                                      Exportar Disparo
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      href="#"
                                      onClick={exportarPesquisa}
                                    >
                                      Exportar Pesquisa
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>

                  <div className="alert-content-adm">
                    {status.type === 'error' ? (
                      <p className="alert-danger">{status.mensagem}</p>
                    ) : (
                      ''
                    )}
                    {status.type === 'success' ? (
                      <p className="alert-success">{status.mensagem}</p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                {atendimentosPresenciais.length > 0 && (
                  <>
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <p className="ml-4 mt-4 text-primary font-weight-bold">
                          Resultados:&nbsp;
                          {atendimentosPresenciais.length}
                        </p>
                      </div>

                      <div className="col-auto mt-3">
                        <div className="card border-left-success shadow h-100 py-2">
                          <div className="card-body p-3">
                            <div className="row no-gutters align-items-center">
                              <div className="col mr-5">
                                <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                  Assinaram
                                </div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                  {atendimentosPresenciais.filter((a) => a.pessoa && a.pessoa.assinouContrato === 'S').length}
                                </div>
                              </div>
                              <div className="col-auto">
                                <i className="fas fa-check-circle fa-2x text-success"> </i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-auto mt-3">
                        <div className="card border-left-danger shadow h-100 py-2">
                          <div className="card-body p-3">
                            <div className="row no-gutters align-items-center">
                              <div className="col mr-5">
                                <div className="text-xs font-weight-bold text-danger text-uppercase mb-1">
                                  Não Assinaram
                                </div>
                                <div className="h5 mb-0 font-weight-bold text-gray-800">
                                  {atendimentosPresenciais.filter((a) => a.pessoa && a.pessoa.assinouContrato === 'N').length}
                                </div>
                              </div>
                              <div className="col-auto">
                                <i className="fas fa-times-circle fa-2x text-danger"> </i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="ml-4 mt-4 text-primary font-weight-bold">
                      { Object.keys(atendimentosGroupedByBeneficios).map((entrie) => (
                        <>
                          <Badge bg="secondary">
                            {`${entrie}: ${atendimentosGroupedByBeneficios?.[entrie]?.length}`}
                          </Badge>
                          &nbsp;&nbsp;
                        </>
                      ))}
                    </p>
                  </>
                )}
                {spinnerSearch ? (
                  <div className="d-flex justify-content-center">
                    <Spinner
                      as="span"
                      animat
                      ion="border"
                      size="lg"
                      role="status"
                      aria-hidden="true"
                    >
                      <span className="sr-only">Buscando...</span>
                    </Spinner>
                  </div>
                ) : (
                  <TabelaAtendimentosPresenciais
                    atendimentosPresenciais={atendimentosPresenciais}
                    unidades={unidades}
                    showFloatAlertSuccess={showFloatAlertSuccess}
                    searchAgendamentos={searchAgendamentos}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LogOutModal />
    </>
  );
}
