import React, { useState } from 'react';

export default function FechamentoIndicacao() {
  const [ligacoes, setLigacoes] = useState([
    {
      id: 1,
      dataLigacao: '2025-02-11',
      situacao: 'Agendado',
      observacao: 'Cliente interessado, retorno marcado.',
      beneficio: 'Loas Deficiente',
    },
    {
      id: 2,
      dataLigacao: '2025-02-11',
      situacao: 'Indicação',
      observacao: 'Indicado por Maria.',
      beneficio: 'Loas Idoso',
    },
  ]);

  const handleSituacaoChange = (id, novaSituacao) => {
    setLigacoes((prevLigacoes) => prevLigacoes.map((ligacao) => (ligacao.id === id ? { ...ligacao, situacao: novaSituacao } : ligacao)));
  };

  return (
    <div className="card p-4">
      <h4>Fechamento Diário por Indicação</h4>
      {ligacoes.map((ligacao) => (
        <div key={ligacao.id} className="mb-3 p-3 border rounded">
          <p>
            <strong>Data da Ligação:</strong>
            {' '}
            {ligacao.dataLigacao}
          </p>

          <label><strong>Situação:</strong></label>
          <select
            className="form-select mb-2"
            value={ligacao.situacao}
            onChange={(e) => handleSituacaoChange(ligacao.id, e.target.value)}
          >
            <option value="Agendado">Agendado</option>
            <option value="Indicação">Indicação</option>
            <option value="Observação">Observação da Indicação</option>
          </select>

          <p>
            <strong>Observação:</strong>
            {' '}
            {ligacao.observacao}
          </p>
          <p>
            <strong>Benefício do Cliente:</strong>
            {' '}
            {ligacao.beneficio}
          </p>
        </div>
      ))}
    </div>
  );
}
