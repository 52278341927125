import React from 'react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

export default function ExportToExcel({ data, fileName }) {
  const exportToExcel = () => {
    if (!data || data.length === 0) {
      alert('Nenhum dado disponível para exportação.');
      return;
    }
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Dados');

    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob(
      [excelBuffer],
      { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' },
    );
    saveAs(dataBlob, `${fileName}.xlsx`);
  };

  return (
    <button type="button" onClick={exportToExcel} className="btn btn-secondary w-100">
      <FontAwesomeIcon icon={faFile} className="me-2" />
      Exportar
    </button>

  );
}

ExportToExcel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array.isRequired,
  fileName: PropTypes.string.isRequired,
};
